/* eslint-disable */
// tslint:disable
/**
 * My Cookery Book 2 - backend
 * This is rest api application backend developed on NodeJS and ExpressJS. Writen in typescript.
 *
 * OpenAPI spec version: 1.2.0
 * Contact: tfilosk@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator+.
 * https://github.com/karlvr/openapi-generator-plus
 * Do not edit the class manually.
 */

export interface ConfigurationParameters {
	apiKey?: string | ((name: string) => string | null);
	username?: string;
	password?: string;
	authorization?: string | ((name: string, scopes?: string[]) => string | null);
	basePath?: string;
}

export class Configuration {
	/**
	 * parameter for apiKey security
	 * @param name security name
	 * @memberof Configuration
	 */
	apiKey?: string | ((name: string) => string | null);
	/**
	 * parameter for basic security
	 * 
	 * @type {string}
	 * @memberof Configuration
	 */
	username?: string;
	/**
	 * parameter for basic security
	 * 
	 * @type {string}
	 * @memberof Configuration
	 */
	password?: string;
	/**
	 * parameter for oauth2, openIdConnect or http security
	 * @param name security name
	 * @param scopes oauth2 scopes
	 * @memberof Configuration
	 */
	authorization?: string | ((name: string, scopes?: string[]) => string | null);
	/**
	 * override base path
	 * 
	 * @type {string}
	 * @memberof Configuration
	 */
	basePath?: string;

	constructor(param: ConfigurationParameters = {}) {
		this.apiKey = param.apiKey;
		this.username = param.username;
		this.password = param.password;
		this.authorization = param.authorization;
		this.basePath = param.basePath;
	}
}
